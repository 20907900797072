

const session = {
  setSession: (value) => {
    sessionStorage.setItem('session', JSON.stringify(value))
  },
  getSession: () => {
    return sessionStorage.getItem('session') ? JSON.parse(sessionStorage.getItem('session')) : null
  },
  setData: (name, data) => {
    return typeof data == 'object' ? sessionStorage.setItem([name], JSON.stringify(data)) : sessionStorage.setItem([name], data)
  },
  getUserInfo: () => {
    return JSON.parse(sessionStorage.getItem('userInfo'))
  }

}

export default session